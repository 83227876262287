import React from 'react';
import './loadingicon.css'; // Ensure your CSS file exists and is properly styled
import 'ldrs/ring';

const LoadingIcon = () => {
  return (
    <div className="loading-icon">
<div class="container">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>

</div>
  );
};

export default LoadingIcon;
